import * as THREE from "three";

function FP_2020_07_22_Jack_Floating_Shapes(mount, viewport) {
  let scene = new THREE.Scene();
  let camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    1,
    2000
  );

  camera.position.x = 0;
  camera.position.y = 0;
  camera.position.z = 0;
  let HemiLight = new THREE.HemisphereLight(0xffeeb1, 0x080820, 4);
  scene.add(HemiLight);

  let renderer = new THREE.WebGLRenderer({
    antialias: true,
  });
  renderer.setClearColor(0xf0f2f5, 1);
  // renderer.setSize(viewport.width, viewport.height);
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.toneMapping = THREE.ReinhardToneMapping;
  mount.appendChild(renderer.domElement);

  var group = new THREE.Group();
  scene.add(group);

  var geometries = [
    new THREE.BoxBufferGeometry(0.2, 0.2, 0.2),
    new THREE.ConeBufferGeometry(0.2, 0.2, 64),
    new THREE.CylinderBufferGeometry(0.2, 0.2, 0.2, 64),
    new THREE.IcosahedronBufferGeometry(0.2, 3),
    new THREE.TorusBufferGeometry(0.2, 0.04, 64, 32),
  ];

  var objects = [];
  for (var i = 0; i < 1000; i++) {
    var geometry = geometries[Math.floor(Math.random() * geometries.length)];
    var material = new THREE.MeshStandardMaterial({
      color: Math.random() * 0xffffff,
      roughness: 0.7,
      metalness: 0.0,
    });

    var object = new THREE.Mesh(geometry, material);

    object.position.x = Math.random() * 48 - 24;
    object.position.y = Math.random() * 48 - 24;
    object.position.z = Math.random() * 48 - 24;

    object.rotation.x = Math.random() * 2 * Math.PI;
    object.rotation.y = Math.random() * 2 * Math.PI;
    object.rotation.z = Math.random() * 2 * Math.PI;

    object.scale.setScalar(Math.random() + 3);

    object.castShadow = true;
    object.receiveShadow = true;
    objects.push(object);

    group.add(object);
  }

  let animate = () => {
    requestAnimationFrame(animate);
    renderer.render(scene, camera);
    objects.forEach((o) => {
      o.rotation.x += 0.001;
    });
    group.rotation.y += 0.0002;
    group.rotation.z += 0.0002;
  };
  animate();

  let onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    viewport.width = window.innerWidth;
    viewport.height = window.innerHeight;
  };
  window.addEventListener("resize", onWindowResize, false);

  let onMouseMove = (e) => {
    let x = e.clientX;
    let y = e.clientY;

    camera.position.x = 0.0002 * (x - 0.5 * viewport.width);
    camera.position.y = 0.0002 * (y - 0.5 * viewport.height);
    camera.rotation.y = 0.00005 * (x - 0.5 * viewport.width);
    camera.rotation.x = 0.00005 * (y - 0.5 * viewport.height);
  };

  window.addEventListener("mousemove", onMouseMove, false);
}

export const FrontPageScene = FP_2020_07_22_Jack_Floating_Shapes;
