// React
import React from "react";

import PropTypes from "prop-types";

// Three
import * as THREE from "three";

// Scrollbars
import { ScrollBars } from "../ScrollBars";

// Material
import { fade, withStyles, makeStyles } from "@material-ui/core/styles";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import SvgIcon from "@material-ui/core/SvgIcon";

import Collapse from "@material-ui/core/Collapse";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import AdjustIcon from "@material-ui/icons/Adjust";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

// Massless
import { useSpace } from "../../context/SpaceContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  panelRoot: {
    opacity: "inherit",
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down("md")]: {
      height: "auto%",
      marginTop: "4px",
      overflow: "visible",
    },
    [theme.breakpoints.up("md")]: {
      height: "45%",
      overflow: "hidden",
    },
  },
  sectionTitle: {
    fontSize: "0.875rem",
    padding: "4px 20px",
  },
}));

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    // "&:hover > $content": {
    //   backgroundColor: "#CFCBFF",
    // },
    // "&:focus > $label": {
    //   backgroundColor: "#CFCBFF",
    // },
    // "&:focus > $content, &$selected > $content": {
    //   backgroundColor: "#CFCBFF",
    //   color: "var(--tree-view-color)",
    // },
    // "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
    //   backgroundColor: "#CFCBFF",
    // },
  },
  content: {
    paddingRight: theme.spacing(4),
    "&:hover": {
      backgroundColor: "#CFCBFF",
    },
  },
  group: {
    marginLeft: 0,
    paddingLeft: 15,
    backgroundColor: "#E7EAF2",
  },
  expanded: {},
  selected: {
    backgroundColor: "#C3CBE0",
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
    padding: 0,
    // "&:focus > $content, &$selected > $content": {
    //   backgroundColor: "#000000 !important",
    // },
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    width: "8px",
    // marginRight: theme.spacing(1),
  },
  labelText: {
    fontSize: "0.75rem",
    fontWeight: "inherit",
    flexGrow: 1,
  },
  labelInfo: { display: "flex" },
  treeItem: {
    // "&:hover": {},
    // //When Clicked (Full Width and rounded end)
    // "&:focus > $content, &$selected > $content": {
    //   backgroundColor: "#C3CBE0",
    // },
    // "&$selected > $content $label": {
    //   backgroundColor: "none !important",
    // },
  },
  iconContainer: { marginRight: 0 },
}));

function ObjectIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M9.42105 5C9.25374 5 9.0914 5.05694 8.96075 5.16147L5.28012 8.10597C5.10949 8.24092 5 8.44979 5 8.68423V17.5263C5 17.9333 5.3299 18.2632 5.73684 18.2632H14.5789C14.7558 18.2632 14.9181 18.2009 15.0451 18.097L18.7235 15.1543C18.8982 15.0145 19 14.8028 19 14.5789V5.73684C19 5.3299 18.6701 5 18.2632 5H9.42105ZM15.3158 15.9932L17.5263 14.2248V7.26993L15.3158 9.03835V15.9932ZM14.3205 7.94739L16.1626 6.47368H9.67952L7.83739 7.94739H14.3205ZM6.47368 16.7895V9.42107H13.8421V16.7895H6.47368Z"
      />
    </SvgIcon>
  );
}

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      className={classes.treeItem}
      label={
        <div className={classes.labelRoot}>
          {LabelIcon}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography
            variant="caption"
            className={classes.labelInfo}
            style={{
              color: props.inScene ? "#00f5a6" : "#f50057",
            }}
            // color="inherit"
          >
            {labelInfo}
          </Typography>
          {/* <IconButton
            size="small"
            className={classes.iconButton}
            aria-label="copy link to clipboard"
            onClick={() => {
              props.callbacks.removeNodeClicked(spaceNode);
            }}
          >
              <DeleteIcon />
            </IconButton> */}
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

function getNodeColor(spaceNode) {
  let calcColor = "#000000";
  if (spaceNode != null) {
    if (spaceNode.inScene) {
      calcColor = "#000000";
    }
  }

  return { color: calcColor };
}

function OutlineItem(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);

  // const [expanded, setExpanded] = React.useState([]);
  // const [selected, setSelected] = React.useState([]);

  // const handleToggle = (event, nodeIds) => {
  //   setExpanded(nodeIds);
  // };

  // const handleSelect = (event, nodeIds) => {
  //   setSelected(nodeIds);
  // };

  const render = () => {
    return (
      <>
        <Box className={classes.outlineItem}>
          <StyledTreeItem
            bgColor={"#181932"}
            nodeId={props.outlineNode.spaceNode.id}
            labelIcon={<ObjectIcon fontSize="small" />}
            labelText={props.outlineNode.spaceNode.name}
            labelInfo={
              <AdjustIcon
                fontSize="small"
                style={{
                  color: props.inScene ? "#00f5a6" : "#f50057",
                }}
              />
            }
            onLabelClick={(event) => {
              console.log("selected node ref");
              console.log(props.outlineNode.spaceNode.ref);
              props.callbacks.setSelectedNodeRef(
                props.outlineNode.spaceNode.ref
              );
              event.preventDefault();
            }}
            onClick={() => {
              props.callbacks.highlightObject(props.outlineNode.spaceNode.id);
            }}
            // onMouseLeave={() => {
            //   props.callbacks.unhighlightObject(props.outlineNode.spaceNode.id)
            // }}
            inScene={props.outlineNode.spaceNode.inScene}
            // onClick={() => {
            //   props.callbacks.setInspectorNode(props.outlineNode.spaceNode);
            // }}
          >
            {props.outlineNode.children.map((childOutlineNode, index) => {
              return (
                <OutlineItem
                  key={index}
                  inScene={childOutlineNode.spaceNode.inScene}
                  outlineNode={childOutlineNode}
                  callbacks={props.callbacks}
                />
              );
            })}
          </StyledTreeItem>
        </Box>
      </>
    );
  };
  return render();
}

export function Outline(props) {
  const classes = useStyles();

  const { addSnack } = useSnackbar();

  const removeNodeClicked = () => {
    //alert("remove node clicked");
    addSnack({ severity: "info", message: "Removing node..." });
    props.selected.forEach((nodeId) => {
      props.spaceProxy.removeNode(nodeId);
    });
  };

  const generateHierarchy = () => {
    return (
      <TreeView
        expanded={props.expanded}
        onNodeToggle={props.callbacks.handleToggle}
        // selected={props.selected}
        onNodeSelect={props.callbacks.handleSelect}
        className={classes.root}
        defaultExpanded={["3"]}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        // onNodeSelect={(o, v) => {
        //   setSelected(v);
        // }}

        multiSelect
      >
        {props.outline.children != null &&
          props.outline.children.map((outlineNode) => {
            return (
              <OutlineItem
                outlineNode={outlineNode}
                callbacks={{ removeNodeClicked, ...props.callbacks }}
              />
            );
          })}
      </TreeView>
    );
  };

  const render = () => {
    return (
      <>
        <Box className={classes.panelRoot}>
          {/* <Box display="flex" alignItems="center"> */}
          {/* Title */}
          <Box className={classes.sectionTitle}>
            Hierarchy
            <Divider />
          </Box>
          {/* </Box> */}
          <Hidden smDown>
            <ScrollBars style={{ height: "100%" }} autoHide>
              {generateHierarchy()}
            </ScrollBars>
          </Hidden>
          <Hidden mdUp>{generateHierarchy()}</Hidden>
          <Divider />
        </Box>
        {/* <Box>
          <IconButton
            onClick={() => {
              removeNodeClicked();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box> */}
      </>
    );
  };

  return render();
}
