// React
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// Material
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import GridListTile from "@material-ui/core/GridListTile";
import Card from "@material-ui/core/Card";
import Menu from "@material-ui/core/Menu";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import CollectionsIcon from "@material-ui/icons/Collections";
import CardMedia from "@material-ui/core/CardMedia";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";

import SvgIcon from "@material-ui/core/SvgIcon";
import LinkIcon from "@material-ui/icons/Link";
import AdjustIcon from "@material-ui/icons/Adjust";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import BackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import ShareIcon from "@material-ui/icons/Share";

import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExtensionIcon from "@material-ui/icons/Extension";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityIcon from "@material-ui/icons/Visibility";

// Massless
import * as Services from "../../massless/Services";

import { useSnackbar } from "../../context/SnackbarContext";
import { useSpace } from "../../context/SpaceContext";
import { useAuth } from "../../context/AuthContext";
import { sAnalyticsTrack } from "../../massless/AnalyticsClient";
import { useKeys } from "../../context/KeysContext";

import { CardMedia3D_Single } from "../CardMedia3D";

// Gfx
import SceneThumb1x from "../../imgs/SceneThumb@1x.png";
import SceneThumb2x from "../../imgs/SceneThumb@2x.png";

const useStyles = makeStyles((theme) => ({
  // From NewProjectGrid.js
  control: {
    padding: theme.spacing(2),
  },
  projectCard: {
    fontWeight: 400,
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
    borderRadius: "0",
    paddingBottom: "8px",
    overflow: "visible",
  },
  projectCardMedia: {
    borderRadius: "10px",
  },
  spaceNameType: {
    fontSize: "16px",
    overflow: "ellipsis",
    margin: 0,
  },
  spaceNameTypeEmpty: {
    fontSize: "16px",
    overflow: "ellipsis",
    margin: 0,
    opacity: 0.5,
  },
  projDescription: {
    fontSize: "12px",
    lineHeight: "14px",
  },
  spaceInfo: {
    maxWidth: "54%",
    background: "transparent",
    padding: "0",
    margin: "auto 0",
  },
  spaceName: {
    display: "flex",
    height: "22px",
    alignItems: "center",
  },
  spaceStatsOverlay: {
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: 0,
    background:
      "linear-gradient(180deg, rgba(36, 15, 124, 0.4) 0%, rgba(36, 15, 124, 0) 25%)",
  },
  spaceStats: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 0,
    right: 0,
    color: "#ffffff",
    padding: "10px 10px",
  },
  spaceStat: {
    display: "flex",
    alignItems: "center",
    marginLeft: "6px",
  },
  iconButton: {
    color: "#A9BDF1",
    background: "#ffffff",
    padding: "4px",
    margin: "0 4px",
    "&:hover": {
      color: "#ffffff",
      background: "#6E54F4",
    },
  },
  cardActionArea: {
    display: "flex",
    marginTop: "8px",
  },
  toolTip: {
    fontSize: "14px",
    backgroundColor: "#050510",
    borderRadius: "8px",
  },
  threeCanvas: {
    borderRadius: "10px",
  },
  emailField: {
    display: "flex",
    alignItems: "flex-end",
    marginLeft: "16px",
  },
  chipList: {
    display: "flex",
    minHeight: "36px",
    padding: "0 16px",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
  },
  chip: {
    margin: "10px 2px 2px",
  },
  userAvatar: {
    padding: 0,
    marginRight: "5px",
    width: "24px",
    height: "24px",
  },
}));

function CommentIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2 2.89542 2.89543 2 4 2H20C21.1046 2 22 2.89542 22 4V16C22 17.1046 21.1046 18 20 18H16L12 22L8 18H4C2.89543 18 2 17.1046 2 16V4ZM6 6H18V8H6V6ZM18 9H6V11H18V9ZM6 12H18V14H6V12Z"
      />
    </SvgIcon>
  );
}

const StyleChip = withStyles({
  root: {
    backgroundColor: "secondary",
  },
})(Chip);

const StyledMenu = withStyles({
  paper: {
    // border: '1px solid #d3d4d5',
    backgroundColor: "#222343",
    border: "solid 1px #393384",
    borderRadius: "8px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledBadge = withStyles((theme) => ({
  badge: {
    // right: -5,
    // top: 13,
    // border: `2px solid #222343`, //${theme.palette.background.paper}`,
    padding: "0 4px",
    // backgroundColor: "#FFFFFF",
    // color: "#000000"
  },
}))(Badge);

const StyledMenuItem = withStyles((theme) => ({
  // root: {
  //   "&:focus": {
  //     backgroundColor: "#181932",
  //     "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
  //       color: theme.palette.common.white,
  //     },
  //   },
  // },
}))(MenuItem);

export function LargeSpaceCard(props) {
  // #region Analytics ******************************************
  const { profile } = useAuth();

  const trackUser = (trackId, properties = {}) => {
    if (profile == null) {
      sAnalyticsTrack(trackId, properties);
      return;
    }
    let userId = profile.sub.split("|")[1];
    sAnalyticsTrack(trackId, {
      ...{ id: userId, email: profile.email },
      ...properties,
    });
  };
  //#endregion
  // #region Space ******************************************
  const classes = useStyles();
  const params = useParams();
  const {
    addSnack,
    addSuccessSnack,
    addErrorSnack,
    addInfoSnack,
  } = useSnackbar();

  const { bindKeys, unbindKeys, registerKeys } = useKeys();

  const {
    deleteSpace,
    listNodes,
    getClientStatus,
    setSpaceProperties,
    getSpaceThumbnail,
    listComments,
    setUserRole,
    getPermissions,
    getSpaceInfo,
  } = useSpace();

  const [inviteEmail, setInviteEmail] = React.useState("");
  const [collaborators, setCollaborators] = React.useState([]);

  const spaceInfo = {
    studioId: props.studioId,
    projectId: props.projectId,
    spaceId: params.spaceId,
  };

  const refreshCollaborators = () => {
    getPermissions(spaceInfo).then((res) => {
      //console.log("getpermissions")
      //console.log(res.toObject())
      const collaborators = res.toObject().collaboratorsList;
      //console.log(collaborators)
      setCollaborators(collaborators == null ? [] : collaborators);
    });
  };
  //#endregion
  // #region Canvas ******************************************
  const [threeCanvas, setThreeCanvas] = React.useState(null);
  React.useEffect(() => {
    if (threeCanvas == null) return;
    CardMedia3D_Single(threeCanvas);
  }, [threeCanvas]);
  //#endregion
  // #region Thumbnail ******************************************
  const [loading, setLoading] = React.useState(false);
  const [spaceThumb, setSpaceThumb] = React.useState(
    "url(http://localhost:3000/img/SceneThumb@1x.png)"
  );

  React.useEffect(() => {
    if (
      `url(https://storage.googleapis.com/massless-space-content/Spaces/${props.spaceInfo.spaceId}/thumbnail.png)` !=
      null
    ) {
      setSpaceThumb(
        `url(https://storage.googleapis.com/massless-space-content/Spaces/${props.spaceInfo.spaceId}/thumbnail.png)`
      );
    }
  }, []);

  //#endregion
  // #region Badges ******************************************
  const [numberOfNodes, setNumberOfNodes] = React.useState(0);
  const [numberOfViews, setNumberOfViews] = React.useState(0);
  const [numberOfComments, setNumberOfComments] = React.useState(0);

  const getBadgeData = () => {
    listNodes(props.spaceInfo).then((res) => {
      setNumberOfNodes(res.getNodesList().length);
    });
    getClientStatus(props.spaceInfo).then((res) => {
      setNumberOfViews(res.toObject().clientsList.length);
    });
    listComments(props.spaceInfo).then((comments) => {
      setNumberOfComments(comments.length);
    });
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    getBadgeData();

    return function cleanup() {
      abortController.abort();
    };
    //console.log("Space Info");
    //console.log(props.spaceInfo);
    // getSpaceThumbnail(props.spaceInfo)
    //   .then((res) => {
    //     const photoHeader = "data:image/png;base64";
    //     const photoPayload = res.getThumbnail_asB64();
    //     if (photoPayload != "") {
    //       const photoData = photoHeader + "," + photoPayload;
    //       setSpaceThumb(photoData);
    //     } else {
    //       setSpaceThumb(SceneThumb1x);
    //     }
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  }, []);

  const [spaceCreator, setSpaceCreator] = React.useState("");

  React.useEffect(() => {
    /** Mount ******************************************/
    // Get space info on load
    getSpaceInfo(props.spaceInfo).then(async (remoteSpaceInfo) => {
      setSpaceCreator(
        await Services.getUserProfile(remoteSpaceInfo.createmetadata.userid)
      );
    });
  }, []);
  //#endregion
  // #region Menu ******************************************
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //#endregion
  // #region Delete ******************************************
  const [deleteSpaceInfo, setDeleteSpaceInfo] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const executeDeleteSpace = () => {
    deleteSpace(deleteSpaceInfo)
      .then((response) => {
        setOpenDeleteDialog(false);
        props.callbacks.refreshSpaces();
        // snackbar.setOpen(true);
        // snackbar.setInfo({
        //   severity: "success",
        //   message: "Space has been deleted",
        // });
      })
      .catch((err) => {
        console.error(err);
        // snackbar.setOpen(true);
        // snackbar.setInfo({
        //   severity: "error",
        //   message: "Could not delete space!",
        // });
        setOpenDeleteDialog(false);
      });
  };

  const deleteSpaceClicked = (spaceInfo) => {
    setDeleteSpaceInfo(spaceInfo);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };
  //#endregion
  // #region Edit ******************************************
  const [spaceName, setSpaceName] = React.useState(
    props.spaceInfo.properties.name
  );
  const [spaceDescription, setSpaceDescription] = React.useState(
    props.spaceInfo.properties.description
  );
  const [openEditSpaceDialog, setOpenEditSpaceDialog] = useState(false);
  const [editedSpaceName, setEditedSpaceName] = React.useState(
    props.spaceInfo.properties.name
  );
  const [editedSpaceDescription, setEditedSpaceDescription] = React.useState(
    props.spaceInfo.properties.description
  );

  const [editSpaceInfo, setEditSpaceInfo] = useState({});

  const editSpaceTitleChange = (e) => {
    setEditedSpaceName(e.target.value);
  };
  const editSpaceDescriptionChange = (e) => {
    setEditedSpaceDescription(e.target.value);
  };

  const editSpaceClicked = (spaceInfo) => {
    // setEditedSpaceName(spaceInfo.name);
    // setEditedSpaceDescription(spaceInfo.description);
    setEditSpaceInfo(spaceInfo);
    setOpenEditSpaceDialog(true);
  };

  const executeEditSpace = () => {
    let editedSpaceProperties = editSpaceInfo;
    editedSpaceProperties.name = editedSpaceName;
    editedSpaceProperties.description = editedSpaceDescription;
    editedSpaceProperties.tagsList = props.spaceInfo.properties.tagsList;
    editedSpaceProperties.studioId = props.spaceInfo.studioId; // this is getting lost somewhere

    setSpaceName(editedSpaceName);
    setSpaceDescription(editedSpaceDescription);

    setOpenEditSpaceDialog(false);

    setSpaceProperties(editedSpaceProperties)
      .then((response) => {
        //refreshSpaces();
        addSnack({
          severity: "success",
          message: "Space has been updated",
        });
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not update Space",
        });
      });
  };
  //#endregion
  // #region Share ******************************************
  const [openShareDialog, setOpenShareDialog] = React.useState(false);

  const [emailChips, setEmailChips] = React.useState([]);

  const [emailChipsInput, setEmailChipsInput] = React.useState("");

  function shareKeyPress(e) {
    if (e.keyCode == 13 || e.keyCode == 188 || e.keyCode == 32) {
      updateEmailChipsClicked();
    }
  }

  function setShareDialogOpen(state) {
    setOpenShareDialog(state);
    if (state) {
      unbindKeys();
    } else {
      bindKeys();
    }
  }

  const handleEmailChipDelete = (emailToDelete) => (event) => {
    let currentEmailChips = emailChips;
    console.info("You deleted an email address");
    setEmailChips(currentEmailChips.filter((e) => e !== emailToDelete)); // will return ['A', 'C']
  };

  const handleEmailChipClick = () => {
    console.info("You clicked the Email Chip.");
  };

  const updateEmailChipsClicked = () => {
    if (
      emailChipsInput.length >= 6 &&
      emailChipsInput.includes("@") &&
      !emailChipsInput.includes(" ") &&
      !emailChipsInput.includes(",")
    ) {
      let newEmail = [...emailChips, emailChipsInput];
      setEmailChips(newEmail);
      setEmailChipsInput("");
    } else {
      addSnack({
        severity: "error",
        message: "This is not a valid email address",
      });
    }
  };

  const emailChipsInputChange = (e) => {
    if (e.target.value != " " && e.target.value != ",") {
      setEmailChipsInput(e.target.value);
    }
    // setSpaceTags((prev) => (
    //   [...prev,
    //     e.target.value]
    // ));
  };

  const sendEmailTo = (email) => {
    Services.sendShareEmailWithSnacks(
      setUserRole,
      props.spaceInfo,
      profile,
      email,
      {
        setShareDialogOpen: setShareDialogOpen,
        refreshCollaborators: refreshCollaborators,
        addSuccessSnack: addSuccessSnack,
        addErrorSnack: addErrorSnack,
        addInfoSnack: addInfoSnack,
      }
    );
  };

  const shareSpaceInviteClicked = () => {
    if (emailChips.length < 1) {
      if (
        emailChipsInput.length >= 6 &&
        emailChipsInput.includes("@") &&
        !emailChipsInput.includes(" ") &&
        !emailChipsInput.includes(",")
      ) {
        sendEmailTo(emailChipsInput);
      } else {
        addSnack({
          severity: "error",
          message: "This is not a valid email address",
        });
      }
    } else {
      emailChips.map((email) => {
        sendEmailTo(email);
      });
    }
  };
  //#endregion
  // #region Copy ******************************************
  const copySpaceClicked = (spaceInfo) => {
    let link = process.env.REACT_APP_ROOT + "/s/" + spaceInfo.spaceId;
    navigator.clipboard.writeText(link).then(
      () => {
        /* clipboard successfully set */
        addSnack({
          severity: "success",
          message: "Space link copied to clipboard!",
        });

        setTimeout(() => {
          addSnack({
            severity: "info",
            message: "Paste into Unity or Blender to connect",
          });
        }, 4000);
      },
      () => {
        /* clipboard error */
        addSnack({
          severity: "error",
          message: "Could not copy link to clipboard",
        });
      }
    );
  };
  //#endregion
  //#region Popover
  const [popAnchorEl, setPopAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setPopAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopAnchorEl(null);
  };

  const open = Boolean(popAnchorEl);
  //#endregion

  return (
    <>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <Card
          className={classes.projectCard}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <CardActionArea className={classes.projectCardMedia}>
            <Link to={"/s/" + props.spaceInfo.spaceId}>
              {/**************************** CardMedia ********************************/}
              <Box
                style={{
                  position: "relative",
                  paddingTop: "66.5%",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "10px",
                  border: "1px solid #D9DFF2",
                  // boxShadow: "0px 2px 6px rgba(33, 6, 170, 0.3)",
                  backgroundImage: spaceThumb,
                  backgroundPosition: "center",
                  backgroundColor: "#101127",
                  "&:hover": {
                    backgroundColor:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 29.17%, rgba(0, 0, 0, 0) 71.87%, rgba(0, 0, 0, 0) ",
                  },
                  overflow: "auto",
                }}
              >
                {loading && (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <CircularProgress color="primary" />
                  </Box>
                )}
                {open ? (
                  <Box className={classes.spaceStatsOverlay}>
                    <Box className={classes.spaceStats}>
                      {numberOfViews != 0 && (
                        <Box className={classes.spaceStat}>
                          <VisibilityIcon fontSize="small" />
                        </Box>
                      )}
                      {numberOfComments != 0 && (
                        <Box className={classes.spaceStat}>
                          <CommentIcon fontSize="small" />
                          {numberOfComments}
                        </Box>
                      )}
                      {numberOfNodes != 0 ? (
                        <Box className={classes.spaceStat}>
                          <AdjustIcon fontSize="small" />
                          {numberOfNodes}
                        </Box>
                      ) : (
                        <Box className={classes.spaceStat}>
                          This space is empty 😞
                        </Box>
                      )}
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Link>
          </CardActionArea>
          <div className={classes.cardActionArea}>
            <CardContent className={classes.spaceInfo}>
              <Box className={classes.spaceName} component="div">
                <Tooltip title={"Created by " + spaceCreator.nickname}>
                  <Avatar
                    alt="User Avatar"
                    className={classes.userAvatar}
                    src={(spaceCreator && spaceCreator.picture) || ""}
                  />
                </Tooltip>
                {spaceName != "" && (
                  <Tooltip title={spaceName}>
                    <Typography
                      noWrap
                      className={classes.spaceNameType}
                      gutterBottom
                      variant="h5"
                      component="h2"
                    >
                      {spaceName}
                    </Typography>
                  </Tooltip>
                )}
                {spaceName == "" && (
                  <Typography
                    noWrap
                    className={classes.spaceNameTypeEmpty}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {"This Space has no name"}
                  </Typography>
                )}
              </Box>
            </CardContent>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifySelf: "flexEnd",
                marginLeft: "auto",
              }}
            >
              <Tooltip title="Share">
                <IconButton
                  className={classes.iconButton}
                  size="small"
                  variant="contained"
                  aria-label="share space via email"
                  onClick={() => {
                    trackUser("space-card-share-clicked", props.spaceInfo);
                    setShareDialogOpen(true);
                  }}
                >
                  <SendIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copy Space URL">
                <IconButton
                  className={classes.iconButton}
                  size="small"
                  aria-label="copy space"
                  onClick={() => {
                    trackUser(
                      "space-card-copy-space-url-clicked",
                      props.spaceInfo
                    );
                    copySpaceClicked(props.spaceInfo);
                  }}
                >
                  <LinkIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Details">
                <IconButton
                  className={classes.iconButton}
                  size="small"
                  aria-label="edit details"
                  onClick={() => {
                    trackUser("space-card-edit-space-clicked", props.spaceInfo);
                    editSpaceClicked(props.spaceInfo);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </div>
        </Card>
      </Grid>
      {/* *********************** CONFIRM DELETE DIALOG **************************** */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this Space?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting this Space will remove it from the Project
            <p>
              Name: <span style={{ color: "#FFFFFF" }}>{spaceName}</span>
            </p>
            <p>
              Description:{" "}
              <span style={{ color: "#FFFFFF" }}>{spaceDescription}</span>
            </p>
            <p>
              ID:{" "}
              <span style={{ color: "#FFFFFF" }}>
                {deleteSpaceInfo.spaceId}
              </span>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              trackUser("delete-space-dialog-no", deleteSpaceInfo);
              handleDeleteDialogClose();
            }}
            color="text.primary"
          >
            Don't delete
          </Button>
          <Button
            onClick={() => {
              trackUser("delete-space-dialog-yes", deleteSpaceInfo);
              executeDeleteSpace();
            }}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* *********************** EDIT SPACE DIALOG **************************** */}
      <Dialog
        open={openEditSpaceDialog}
        onClose={() => {
          trackUser("edit-space-dialog-close", props.spaceInfo);
          setOpenEditSpaceDialog(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Edit your Space</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To update your Space details, please change some information below
          </DialogContentText>
          <TextField
            value={editedSpaceName}
            onChange={editSpaceTitleChange}
            autoFocus
            margin="dense"
            id="title"
            label="Name"
            type="text"
            fullWidth
            color="primary"
          />
          <TextField
            value={editedSpaceDescription}
            onChange={editSpaceDescriptionChange}
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={3}
            color="primary"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              trackUser("edit-space-cancel-click", props.spaceInfo);
              setOpenEditSpaceDialog(false);
            }}
            color="text.primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              trackUser("edit-space-update-click", props.spaceInfo);
              executeEditSpace();
            }}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {/* *********************** SHARE PROJECT DIALOG **************************** */}
      <Dialog
        open={openShareDialog}
        onClose={() => {
          setShareDialogOpen(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Share your Space</DialogTitle>
        <DialogContent style={{ padding: "8px" }}>
          <DialogContentText style={{ padding: "0 16px" }}>
            Invite your friends or colleages to collaborate on your Space.
          </DialogContentText>
          <DialogContentText
            style={{ padding: "0 16px", marginBottom: "16px" }}
          >
            Enter their email addresses below seperated with spaces or commas.
          </DialogContentText>
          <Box className={classes.emailField}>
            <TextField
              value={emailChipsInput}
              onKeyDown={shareKeyPress}
              onChange={emailChipsInputChange}
              autoFocus
              id="title"
              label="Emails"
              type="text"
              fullWidth
              color="primary"
            />
            <Tooltip title="Add Email Address">
              <IconButton
                style={{ margin: "0 8px" }}
                size="small"
                onClick={() => {
                  updateEmailChipsClicked();
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className={classes.chipList} component="ul">
            {emailChips.map((email) => {
              return (
                <li>
                  <StyleChip
                    size="small"
                    className={classes.chip}
                    label={email}
                    onClick={handleEmailChipClick}
                    onDelete={handleEmailChipDelete(email)}
                    color="primary"
                    deleteIcon={
                      <Tooltip title="Delete this email address!">
                        <DeleteIcon />
                      </Tooltip>
                    }
                  />
                </li>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              trackUser("share-project-form-cancel-click", profile, {
                studioId: params.studioId,
                projectId: params.projectId,
              });
              setShareDialogOpen(false);
            }}
            color="text.primary"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              trackUser("share-project-form-invite-click", profile, {
                studioId: params.studioId,
                projectId: params.projectId,
              });
              shareSpaceInviteClicked();
            }}
            color="primary"
          >
            Share
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
