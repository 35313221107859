// React
import React, { useState } from "react";

// Material
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {
  Container,
  Box,
  TextField,
  Button,
  Paper,
  Fab,
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";

// Massless
import { useSnackbar } from "../context/SnackbarContext";
import { FrontPageScene } from "../massless/FrontPageScene";

import SpaceLogo1x from "../imgs/SpaceLogo1x.png";
import SpaceLogo2x from "../imgs/SpaceLogo2x.png";

import Spiral from "../imgs/Massless_Spiral.png";
import SpiralAnim from "../imgs/Massless-Spiral-2Loops.gif";

import { ObjectSpaceNormalMap } from "three";

// > Analytics
import { useAuth } from "../context/AuthContext";
import { sAnalyticsTrack, trackUser } from "../massless/AnalyticsClient";

const useStyles = makeStyles((theme) => ({
  appBody: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    width: "100%",
    height: "calc(100vh - 40px)",
    backgroundColor: "#F0F2F5",
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
      margin: "0",
    },
    [theme.breakpoints.up("md")]: {
      borderRadius: "40px",
      margin: "20px 20px 20px 0",
    },
  },
  loginForm: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "calc(100vh - 55px)",
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 80px)",
      height: "calc(100vh - 40px)",
    },
  },
  loginButton: {
    height: "30px",
    borderRadius: "20px",
    width: "100%",
  },
  formControl: {
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  formLabelControl: {
    margin: "0",
  },
  formLabel: {
    width: "auto",
    fontSize: "14px",
  },
  signInPrompt: {
    whiteSpace: "pre-line",
    fontSize: "18px",
  },
  spaceSpiral: { width: "20%", margin: "auto 5px auto auto" },
  mobileSpaceLogo: {
    width: "70%",
    maxWidth: "300px",
    margin: "auto auto auto 5px",
  },
}));

function GoogleIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M22,11.48c0-0.76-0.06-1.53-0.19-2.28H11.22v4.32h6.06c-0.25,1.39-1.06,2.63-2.24,3.41v0
        c-1.01,0.68-2.3,1.07-3.81,1.07c-2.93,0-5.41-1.98-6.3-4.63h0c-0.47-1.39-0.47-2.9,0-4.3c0.89-2.66,3.37-4.63,6.3-4.63
        c1.6-0.02,3.15,0.58,4.3,1.68l3.2-3.2C16.7,1.02,14.01-0.03,11.22,0C6.98,0,3.1,2.39,1.19,6.19c-1.59,3.17-1.59,6.91,0,10.08
        c1.9,3.79,5.79,6.18,10.03,6.18c3.03,0,5.58-0.99,7.44-2.71l0,0C20.78,17.78,22,14.89,22,11.48z"
      />
    </SvgIcon>
  );
}

var cursor = { x: 0, y: 0 };

function LoginPage() {
  //#region Analytics
  const {
    authStage,
    profile,
    authStart,
    authSignIn,
    authSignOut,
    authorize,
  } = useAuth();

  React.useEffect(() => {
    trackUser("login-page-view");
  }, []);
  //#endregion

  //#region Login
  const classes = useStyles();

  const { addSnack } = useSnackbar();

  const [stage, setStage] = useState("start");
  const [message, setMessage] = useState(" ");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState(" ");

  let viewport = { width: window.innerWidth, height: window.innerHeight };
  const [threeMount, setThreeMount] = useState(null);
  React.useEffect(() => {
    if (threeMount == null) return;
    FrontPageScene(threeMount, viewport);
  }, [threeMount]);

  const emailInputChange = (e) => setEmail(e.target.value);
  const codeInputChange = (e) => setCode(e.target.value);

  const start = async () => {
    console.log("start()");
    console.log(email);

    if (email.length >= 6 && state.termsCheck == true) {
      // TODO: Validation on the input
      authStart(email)
        .then(() => {
          setStage("signIn");
          emailError = false;
        })
        .catch((err) => {
          addSnack({
            severity: "error",
            message: "Problem with email address",
          });
          emailError = true;
          setStage("start");
        });
    } else if (state.termsCheck == true) {
      addSnack({ severity: "error", message: "Email not long enough" });
      emailError = true;
    } else if (state.termsCheck == false) {
      addSnack({ severity: "error", message: "Please read the T&C's" });
      checkboxColor = "primary";
      emailError = false;
    }
  };

  const googleStart = async () => {
    console.log("googleStart()");

    if (state.termsCheck == false) {
      addSnack({ severity: "error", message: "Please read the T&C's" });
      checkboxColor = "primary";
    } else {
      authorize();
    }
  };

  const signIn = async () => {
    console.log("authCode()");
    if (code.length == 6) {
      // TODO: Validation on the input
      authSignIn(code).catch(() => {
        addSnack({ severity: "error", message: "Problem with code" });
      });
    } else {
      addSnack({ severity: "error", message: "Code in six digits" });
    }

    //setStage("start");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (stage == "start") {
      start();
    } else if (stage == "signIn") {
      signIn();
    }
  };
  //#endregion

  //#region T&Cs CheckBox State
  let checkboxColor = "white";

  let emailError = false;

  const TermsCheckbox = withStyles({})((props) => (
    <Checkbox
      color="primary"
      size="medium"
      style={{ marginLeft: "-12px", marginRight: "8px" }}
      icon={<CheckBoxOutlineBlankIcon color={checkboxColor} />}
      {...props}
    />
  ));

  const [state, setState] = React.useState({
    termsCheck: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const termsError = state.termsCheck !== true;
  //#endregion

  //#region Page Render
  const renderStart = () => {
    return (
      <Paper
        elevation={3}
        style={{
          padding: 32,
          margin: 16,
          borderRadius: 20,
          display: "flex",
          flexDirection: "column",
          maxWidth: "440px",
          //background: "rgba(255,255,255,0.9)",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img className={classes.spaceSpiral} src={SpiralAnim} />
            <img
              className={classes.mobileSpaceLogo}
              src={SpaceLogo1x}
              srcSet={SpaceLogo1x + " 1x," + SpaceLogo2x + " 2x"}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ padding: 16, paddingBottom: "0px", textAlign: "center" }}
        >
          <Typography className={classes.signInPrompt}>
            Enter your email to sign in or to sign up
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ margin: 16 }}
        >
          <TextField
            error={emailError}
            inputProps={{ style: { textAlign: "center" } }}
            onChange={emailInputChange}
            onClick={(emailError = false)}
            id="standard-basic"
            label="Your email"
            fullWidth="true"
            autoFocus
            color="primary"
          />
        </Box>
        <FormControl
          required
          error={termsError}
          component="fieldset"
          className={classes.formControl}
        >
          <FormControlLabel
            className={classes.formLabelControl}
            control={
              <TermsCheckbox
                checked={state.termsCheck}
                onChange={handleChange}
                name="termsCheck"
              />
            }
            label={
              <Typography className={classes.formLabel}>
                I have read and agree to the{" "}
                <Link
                  target="_blank"
                  color="primary"
                  href="https://massless.io/terms-conditions/"
                >
                  terms & conditions
                </Link>{" "}
                and{" "}
                <Link
                  target="_blank"
                  color="primary"
                  href="https://massless.io/privacy-policy/"
                >
                  privacy policy
                </Link>{" "}
                and want to receive Massless updates
              </Typography>
            }
          />
        </FormControl>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="16px"
        >
          <Button
            className={classes.loginButton}
            variant="outlined"
            color="primary"
            onClick={() => {
              trackUser("login-start-submit-click", profile, { email: email });
              start();
            }}
          >
            Submit
          </Button>
          <Typography style={{ margin: "16px" }}>Or sign in with:</Typography>
          <Button
            className={classes.loginButton}
            variant="outlined"
            startIcon={<GoogleIcon />}
            onClick={() => {
              googleStart();
            }}
          >
            Google
          </Button>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ color: "red" }}
        >
          {message}
        </Box>
      </Paper>
    );
  };

  const renderSignIn = () => {
    return (
      <Paper
        elevation={3}
        style={{
          padding: 16,
          margin: 16,
          borderRadius: 20,
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* <Box style={{ position: "absolute", width: "0", height: "0" }}> */}
        <Box>
          <IconButton
            size="small"
            color="text.primary"
            aria-label="back"
            onClick={() => {
              setStage("start");
            }}
            // style={{ position: "relative", left: "-30px", top: "-30px" }}
          >
            <BackIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ padding: 16, paddingBottom: "0px", textAlign: "center" }}
        >
          <Typography>
            An email with the code has been sent to <br /> {email}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <TextField
            onChange={codeInputChange}
            id="standard-basic"
            label="Your code"
            fullWidth="true"
            autoFocus
            style={{ margin: 16 }}
            color="primary"
            inputProps={{
              pattern: "[0-9]{6}",
              inputMode: "numeric",
              style: { textAlign: "center" },
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            className={classes.loginButton}
            variant="outlined"
            color="primary"
            style={{ margin: 16 }}
            onClick={() => {
              trackUser("login-sign-in-submit-click", profile, {
                email: email,
              });
              signIn();
            }}
          >
            Submit
          </Button>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ color: "red" }}
        >
          {message}
        </Box>
      </Paper>
    );
  };
  //#endregion

  return (
    <Box className={classes.appBody}>
      {/* <canvas
        ref={(ref) => {
          setThreeMount(ref);
        }}
        style={{
          width: "100%",
          height: "100%",
        }}
      /> */}
      <div
        ref={(ref) => {
          setThreeMount(ref);
        }}
        style={{
          // position: "absolute",
          // top: 0,
          // left: 0,
          width: "100%",
          height: "100%",
          // zIndex: -1,
        }}
      />
      <Box className={classes.loginForm}>
        <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
          {/* <Box display="flex" alignItems="center" justifyContent="center">
            <img 
              src={MasslessSpaceWordmark1x}
              srcSet={MasslessSpaceWordmark1x + " 1x," + MasslessSpaceWordmark2x + " 2x"} 
              // style={{ height: 60 }}
            >
            </img>
          </Box> */}
          {stage == "start" && renderStart()}
          {stage == "signIn" && renderSignIn()}
        </form>
      </Box>
    </Box>
  );
}

export default LoginPage;
