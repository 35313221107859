// React
import React, { useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

export function SpaceCardSkeleton(props) {
  return (
    <>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <Skeleton
          variant="rect"
          //   height={217}
          style={{ paddingTop: "66.5%", borderRadius: "10px" }}
        />
        <Box display="flex" margin="5px 0">
          <Box style={{ marginRight: "5px" }}>
            <Skeleton variant="circle" width={28} height={28} />
          </Box>
          <Skeleton variant="text" width={150} />
          <Box display="flex" marginLeft="auto">
            <Box style={{ marginLeft: "5px" }}>
              <Skeleton variant="circle" width={28} height={28} />
            </Box>
            <Box style={{ marginLeft: "5px" }}>
              <Skeleton variant="circle" width={28} height={28} />
            </Box>
            <Box style={{ marginLeft: "5px" }}>
              <Skeleton variant="circle" width={28} height={28} />
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
}
